import React, {useEffect, useState} from 'react';
import {InputGroup} from "react-bootstrap";
import {Switch} from "@mui/material";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import CustomDateRangePicker from "../../components/CustomDateRangePicker/CustomDateRangePicker";
import CustomDailyRangePicker from "../../components/CustomDailyRangePicker/CustomDailyRangePicker";
import GetZones from "../../queries/Nutitee/GetZones";
import PedestrianStatistics from "../../components/PedestrianStatistics/PedestrianStatistics";
import NutiteeGlobals from "./NutiteeGlobals";
import NutiteeAdvanced from "./NutiteeAdvanced";
import NutiteeMap from "./NutiteeMap";

export default function Nutitee() {
    const {t} = useTranslation();

    const [isDailyView, setIsDailyView] = useState(false);
    const [selectedTimeMode, setSelectedTimeMode] = useState(0);
    const [startDate, setStartDate] = useState(moment().startOf('week').toDate());
    const [endDate, setEndDate] = useState(moment().endOf('week').toDate());
    const [Points, setPoints] = useState([]);


    const setStartAndEndDate = (startDateInput, endDateInput) => {
        setStartDate(() => startDateInput);
        setEndDate(() => endDateInput);
    }

    const handleSetSelectedTimeMode = (mode) => {
        setSelectedTimeMode(mode);
    }

    const toggleDailyView = () => {

        setIsDailyView((prevState) => {
            if (prevState) {
                // SET TO ADVANCED VIEW
                setSelectedTimeMode(0);
                setStartDate(moment().startOf('week').toDate());
                setEndDate(moment().endOf('week').toDate());
            } else {
                // SET TO DAILY VIEW
                setStartDate(moment().startOf('day').toDate());
                setEndDate(moment().endOf('day').toDate());
            }

            return (!prevState);
        });
    }

    const prevDay = () => {
        setStartDate((prev) => moment(prev).subtract(1, 'day').toDate());
        setEndDate((prev) => moment(prev).subtract(1, 'day').toDate());

    }

    const nextDay = () => {
        setStartDate((prev) => moment(prev).add(1, 'day').toDate());
        setEndDate((prev) => moment(prev).add(1, 'day').toDate());
    }

    return (
        <>
            <div className="row">
                <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
                    <div className="card crm-data-card">
                        <div className="text-right"></div>
                        <div className="card-body animated fadeIn fast text-center">
                            <div className="row text-center d-flex align-items-center justify-content-between">
                                <div
                                    className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"}
                                >
                                    <InputGroup className="input-group w-25 mx-auto" style={{ position: 'relative', display: 'flex', flexWrap: 'nowrap', alignItems: 'stretch', width: '100%' }}>
                                        <InputGroup.Text id="inputGroup-sizing-sm">
                                            {t("History view")}
                                        </InputGroup.Text>
                                        &nbsp;&nbsp;
                                        <Switch
                                            checked={isDailyView}
                                            onChange={() => toggleDailyView()}
                                        />
                                        &nbsp;&nbsp;
                                        <InputGroup.Text>{t("Daily view")}</InputGroup.Text>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!isDailyView && <CustomDateRangePicker setSelectedTimeMode={handleSetSelectedTimeMode}
                                                    setStartAndEndDate={setStartAndEndDate}
                                                    selectedTimeMode={selectedTimeMode}/>}

            {isDailyView && <CustomDailyRangePicker end={endDate} prev={prevDay} next={nextDay}
                                                    today={moment().endOf('day').toDate()}/>}

            <>
                <NutiteeGlobals startDate={startDate} endDate={endDate} opened={true}/>
            </>

            <>
<NutiteeMap startDate={startDate} endDate={endDate} />
               <NutiteeAdvanced startDate={startDate} endDate={endDate} opened={false} spc={[2,5]}/>
                <NutiteeAdvanced startDate={startDate} endDate={endDate} opened={false} spc={[5,10]}/>
                <NutiteeAdvanced startDate={startDate} endDate={endDate} opened={false} spc={[10,11]}/>
                <NutiteeAdvanced startDate={startDate} endDate={endDate} opened={false} spc={[11,14]}/>
                
            </>
        </>
    );
}
