import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useTranslation} from "react-i18next";
import {disableLoading, enableLoading} from "../../../../features/loading/loadingSlice";
import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import GetInformation from '../../../../queries/Devices/GetInformation';
import moment from 'moment';
import LightPost from "../../../LightPost/LightPost";


export default function InformationModal(props) {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [messages, setMessages] = useState([]);  // Hoidke sõnumeid
    const [ws, setWs] = useState(null);  // Hoidke WebSocketi ühendust

    const [aBox, setABox] = useState({
        'B': 0,
        'C': 0,
        'P': 0,
    })

    const [bBox, setBBox] = useState({
        'B': 0,
        'C': 0,
        'P': 0,
    })

    const [aData, setAData] = useState({
        last_blink_start: 0,
        last_blink_end: 0,
        bootup_timestamp: 0,
        raspberry_uptime: 0,
        anydesk_id: 0,
        hasBlinked: false
    });

    const [bData, setBData] = useState({
        last_blink_start: 0,
        last_blink_end: 0,
        bootup_timestamp: 0,
        raspberry_uptime: 0,
        anydesk_id: 0,
        hasBlinked: false
    });

    useEffect(() => {
        
        if(props.selectedDevice) {
            updateData(props.selectedDevice.id);
        }
    }, [props.selectedDevice])


    useEffect(() => {
        if(!props.selectedDevice) {
            return;
        }
        if(ws) {
            ws.close();
        }
        // Loo WebSocketi ühendus
        const socket = new WebSocket('wss://apiv2.bercman.com:8080/' + props.selectedDevice.api_key); // Kasutame WSS protokolli

        // Kui ühendus on avatud
        socket.onopen = () => {
        };

        // Kui server saadab sõnumi
        socket.onmessage = (event) => {


            let data = JSON.parse(event.data);

            setABox(data['A']);
            setBBox(data['B']);

            setMessages((prevMessages) => [...prevMessages, event.data]); // Lisa sõnum sõnumite hulka
        };

        // Kui ühendus suletakse
        socket.onclose = () => {
        };

        // Kui ühenduses on viga
        socket.onerror = (error) => {
        };

        // Salvesta WebSocketi ühendus, et hiljem kasutada
        setWs(socket);

        // Ühenduse sulgemine kui komponent eemaldatakse
        return () => {
            socket.close();
        };
    }, [props.selectedDevice]);

    const cleanAndCloseModal = () => {
        ws.close();
        setABox({
            'B': 0,
            'C': 0,
            'P': 0,
        });
        setBBox({
            'B': 0,
            'C': 0,
            'P': 0,
        });
        //props.selectedDevice = null;
        props.hideFn();
    }


    const updateData = (id) => {
        dispatch(enableLoading());

        GetInformation(id).then((result) => {
            dispatch(disableLoading());

            let data = result.data.data;

            let aDataHolder = {
                anydesk_id:0,
                raspberry_uptime:0,
                last_blink_end:0,
                last_blink_start:0,
                bootup_timestamp:0,
                hasBlinked: false
            }

            let bDataHolder = {
                anydesk_id:0,
                raspberry_uptime:0,
                last_blink_end:0,
                last_blink_start:0,
                bootup_timestamp:0,
                hasBlinked: false
            }
            

            data.forEach(item => {
                if(item.core_id === "A" && item.key === "ANYDESK_ID") {
                    aDataHolder.anydesk_id =item.value;
                }
                if(item.core_id === "A" && item.key === "LAST_BLINK_END") {
                    aDataHolder.last_blink_end =item.value;
                }
                if(item.core_id === "A" && item.key === "LAST_BLINK_START") {
                    aDataHolder.last_blink_start =item.value;
                }
                if(item.core_id === "A" && item.key === "RASPBERRY_UPTIME") {
                    aDataHolder.raspberry_uptime =item.value;
                }
                if(item.core_id === "A" && item.key === "BOOTUP_TIMESTAMP") {
                    aDataHolder.bootup_timestamp =item.value;
                }
                if(item.core_id === "B" && item.key === "ANYDESK_ID") {
                    bDataHolder.anydesk_id =item.value;
                }
                if(item.core_id === "B" && item.key === "LAST_BLINK_END") {
                    bDataHolder.last_blink_end =item.value;
                }
                if(item.core_id === "B" && item.key === "LAST_BLINK_START") {
                    bDataHolder.last_blink_start =item.value;
                }
                if(item.core_id === "B" && item.key === "RASPBERRY_UPTIME") {
                    bDataHolder.raspberry_uptime =item.value;
                }
                if(item.core_id === "B" && item.key === "BOOTUP_TIMESTAMP") {
                    bDataHolder.bootup_timestamp =item.value;
                }
               
              });
            

            aDataHolder.hasBlinked = checkLastBlink(aDataHolder.last_blink_start);
            bDataHolder.hasBlinked = checkLastBlink(bDataHolder.last_blink_start);
            setAData(aDataHolder);
            setBData(bDataHolder);

            //setTimeout(() => updateData(id),1000);

        });
    };

    const formatDate = (dateString) => {
        if (!dateString) return "-"; 
        return moment(dateString, "YYYY-MM-DDTHH.mm.ss").format("DD.MM.YYYY HH:mm:ss");
    };

    const formatUptime = (uptime) => {
        if (!uptime) return "-"; // 
        const [days, hours, minutes, seconds] = uptime.split(":").map(Number);
        return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    };
    const checkLastBlink = (lastBlinkDate) => {
        if (!lastBlinkDate) return false; // Handle empty or invalid date
        const lastBlinkMoment = moment(lastBlinkDate, "YYYY-MM-DDTHH.mm.ss");
        const currentMoment = moment();

        // Check if the last blink is more than 1 day ago
        const diffInDays = currentMoment.diff(lastBlinkMoment, 'hours');

        console.log(diffInDays);
        
        if (diffInDays > 12) {
            // Perform the action when more than 1 day has passed
            return false; // This indicates that more than 1 day has passed
        }

        return true; // Last blink is within 1 day
    }
    
    return (<Modal {...props} >
        <Modal.Header>
            {props.selectedDevice && <Modal.Title>{props.selectedDevice.name}</Modal.Title> }
        </Modal.Header>
        <Modal.Body>
            <table className="table table-responsive">
                <tr>
                    <th>{t("Name")}</th>
                    <th>{t("A")}</th>
                    <th>{t("B")}</th>
                </tr>

                <tr>
                    <td>{t("Last blink start")}</td>
                    <td style={aData.hasBlinked ? {color: "green"} : {color: "red"}}>{formatDate(aData.last_blink_start)}</td>
                    <td style={bData.hasBlinked ? {color: "green"} : {color: "red"}}>{formatDate(bData.last_blink_start)}</td>
                </tr>
                
                <tr>
                    <td>{t("Last blink end")}</td>
                    <td>{formatDate(aData.last_blink_end)}</td>
                    <td>{formatDate(bData.last_blink_end)}</td>
                </tr>

                <tr>
                    <td>{t("Bootup timestamp")}</td>
                    <td>{formatDate(aData.bootup_timestamp)}</td>
                    <td>{formatDate(bData.bootup_timestamp)}</td>
                </tr>

                <tr>
                    <td>{t("Raspberry uptime")}</td>
                    <td>{formatUptime(aData.raspberry_uptime)}</td>
                    <td>{formatUptime(bData.raspberry_uptime)}</td>
                </tr>

                <tr>
                    <td>{t("Anydesk ID")}</td>
                    <td>{aData.anydesk_id}</td>
                    <td>{bData.anydesk_id}</td>
                </tr>
            </table>
            <div className={"row"}>
                <div className={"col-lg-5"}><LightPost isLightsBlinking={!!aBox.B} isSignGlowing={!!aBox.P}></LightPost></div>
                <div className={"col-lg-2"}>{!!aBox.C ? "Uhendatud" : "Ühendus puudub!"}</div>
                <div className={"col-lg-5"}><LightPost isLightsBlinking={!!bBox.B} isSignGlowing={!!bBox.P} isFlipped={true}></LightPost></div>
            </div>


        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => cleanAndCloseModal()}>
                {t("Close")}
            </Button>
    
        </Modal.Footer>
    </Modal>);
}
