import React, {useEffect, useState} from 'react';
import './Statistics.css';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from 'react-router-dom';
import {Switch} from "@mui/material";
import {InputGroup} from "react-bootstrap";
import CustomDateRangePicker from "../../components/CustomDateRangePicker/CustomDateRangePicker";
import CustomDailyRangePicker from "../../components/CustomDailyRangePicker/CustomDailyRangePicker";
import moment from "moment";
import GetDeviceQuery from "../../queries/Devices/GetDevice";
import PedestrianStatistics from "../../components/PedestrianStatistics/PedestrianStatistics";
import RadarStatistics from "../../components/RadarStatistics/RadarStatistics";
import AirlyWidget from "../../components/AirlyWidget/AirlyWidget";
import { GetTimeZonesOffSet } from "../../components/TimeZones/TimeZones.js";

export default function Statistics() {
    const currentGroup = JSON.parse(localStorage.getItem("current_group")).id;

    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const [isDailyView, setIsDailyView] = useState(false);
    const [deviceName, setDeviceName] = useState("");
    const [deviceLocation, setDeviceLocation] = useState([]);
    const [offSet, setOffset] = useState(0);
    const [deviceRadars, setDeviceRadars] = useState(0);
    const [selectedTimeMode, setSelectedTimeMode] = useState(0);
    const [startDate, setStartDate] = useState(moment().startOf('week').toDate());
    const [endDate, setEndDate] = useState(moment().endOf('week').toDate());
    const [airlyID, setAirlyID] = useState(null);
    const [isDeviceDataFetched, setIsDeviceDataFetched] = useState(false); 

    const deviceId = params.deviceID;

    const setStartAndEndDate = (startDateInput, endDateInput) => {
        setStartDate(() => moment(startDateInput).add(offSet, 'hours'));
        setEndDate(() => moment(endDateInput).add(offSet, 'hours'));
    }

    useEffect(() => {
        fetchDeviceData(deviceId, currentGroup);
    },[deviceId, currentGroup, offSet]);

    const handleSetSelectedTimeMode = (mode) => {
        setSelectedTimeMode(mode);
    }

    const toggleDailyView = () => {
        setIsDailyView((prevState) => {
            if (prevState) {
                // SET TO ADVANCED VIEW
                setSelectedTimeMode(0);
                setStartDate(moment().startOf('week').toDate());
                setEndDate(moment().endOf('week').toDate());
            } else {
                // SET TO DAILY VIEW
                setStartDate(moment().startOf('day').toDate());
                setEndDate(moment().endOf('day').toDate());
            }

            return (!prevState);
        });
    }

    const prevDay = () => {
        setStartDate((prev) => moment(prev).subtract(1, 'day').toDate());
        setEndDate((prev) => moment(prev).subtract(1, 'day').toDate());
    }

    const nextDay = () => {
        setStartDate((prev) => moment(prev).add(1, 'day').toDate());
        setEndDate((prev) => moment(prev).add(1, 'day').toDate());
    }

    const fetchDeviceData = (id, group) => {
        GetDeviceQuery(id, group).then((result) => {
            setDeviceName(result.data.data.name);
            setDeviceRadars(result.data.data.radars);
            setAirlyID(result.data.data.airly_id);
            if(result.data.data.coordinates.length > 3){
                const coordinates = result.data.data.coordinates.split(";");
                const latitude = parseFloat(coordinates[0]);
                const longitude = parseFloat(coordinates[1]);
                setOffset(GetTimeZonesOffSet(latitude, longitude));
                setDeviceLocation(result.data.data.coordinates.split(";"));
            } else {
                setDeviceLocation([51.651883, -0.177863]);
                setOffset(0);
            }           
            setIsDeviceDataFetched(true);
        });
    }

    useEffect(() => {
        fetchDeviceData(deviceId, currentGroup);
    },[]);

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="page-title-box">
                        <h4 className="page-title">
                            <a className='back-text' style={{textDecoration: "none"}} href="/" onClick={() => navigate(-1)}>
                                <FontAwesomeIcon className='back-button' icon={faChevronLeft}/>
                                &nbsp; {t("Back")}
                            </a>
                            <a className='back-text' style={{textDecoration: "none", marginLeft: "40%"}} href="/">
                                {deviceName}
                            </a>
                        </h4>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
                    <div className="card crm-data-card">
                        <div className="text-right"></div>
                        <div className="card-body animated fadeIn fast text-center">
                            <div className="row text-center">
                                <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"}>
                                <InputGroup className="input-group w-25 mx-auto" style={{ position: 'relative', display: 'flex', flexWrap: 'nowrap', alignItems: 'stretch', width: '100%' }}>
                                        <InputGroup.Text id="inputGroup-sizing-sm">
                                            {t("History view")}
                                        </InputGroup.Text>
                                        &nbsp;&nbsp;
                                        <Switch
                                            checked={isDailyView}
                                            onChange={() => toggleDailyView()}
                                        />
                                        &nbsp;&nbsp;
                                        <InputGroup.Text>{t("Daily view")}</InputGroup.Text>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!isDailyView && <CustomDateRangePicker setSelectedTimeMode={handleSetSelectedTimeMode}
                                                    setStartAndEndDate={setStartAndEndDate}
                                                    selectedTimeMode={selectedTimeMode}/>}

            {isDailyView && <CustomDailyRangePicker end={endDate} prev={prevDay} next={nextDay}
                                                    today={moment().endOf('day').toDate()}/>}

            <AirlyWidget airlyID={airlyID} opened={false}/>
            {/* Render PedestrianStatistics only after data is fetched */}

            {isDeviceDataFetched && (
                <PedestrianStatistics startDate={startDate} endDate={endDate} deviceId={deviceId} opened={false} offSet={offSet}/>
            )}

            {deviceRadars > 0 &&
                <RadarStatistics startDate={startDate} endDate={endDate} deviceRadars={deviceRadars} deviceId={deviceId} opened={true} offSet={offSet}/>
            }
        </>
    );
}
