import moment from "moment-timezone";

const timezoneMap = [
  // Europe
  { lat: 58.5953, lon: 25.0136, timezone: "Europe/Tallinn" }, // Estonia
  { lat: 51.5074, lon: -0.1278, timezone: "Europe/London" }, // London
  { lat: 48.8566, lon: 2.3522, timezone: "Europe/Paris" },   // Paris
  { lat: 52.5200, lon: 13.4050, timezone: "Europe/Berlin" }, // Berlin
  
  // Americas
  { lat: 40.7128, lon: -74.0060, timezone: "America/New_York" }, // New York
  { lat: 34.0522, lon: -118.2437, timezone: "America/Los_Angeles" }, // Los Angeles
  { lat: 19.4326, lon: -99.1332, timezone: "America/Mexico_City" }, // Mexico City
  { lat: -23.5505, lon: -46.6333, timezone: "America/Sao_Paulo" }, // São Paulo

  // Asia
  { lat: 35.6895, lon: 139.6917, timezone: "Asia/Tokyo" },    // Tokyo
  { lat: 31.2304, lon: 121.4737, timezone: "Asia/Shanghai" }, // Shanghai
  { lat: 28.6139, lon: 77.2090, timezone: "Asia/Kolkata" },   // Delhi
  { lat: 1.3521, lon: 103.8198, timezone: "Asia/Singapore" }, // Singapore

  // Africa
  { lat: -1.286389, lon: 36.817223, timezone: "Africa/Nairobi" }, // Nairobi
  { lat: 9.05785, lon: 7.49508, timezone: "Africa/Lagos" },       // Lagos
  { lat: -26.2041, lon: 28.0473, timezone: "Africa/Johannesburg" }, // Johannesburg

  // Oceania
  { lat: -33.8688, lon: 151.2093, timezone: "Australia/Sydney" }, // Sydney
  { lat: -36.8485, lon: 174.7633, timezone: "Pacific/Auckland" }, // Auckland

  // North America
  { lat: 49.2827, lon: -123.1207, timezone: "America/Vancouver" }, // Vancouver
  { lat: 45.4215, lon: -75.6972, timezone: "America/Toronto" },    // Toronto

  // Middle East
  { lat: 25.276987, lon: 55.296249, timezone: "Asia/Dubai" },     // Dubai
  { lat: 31.768319, lon: 35.21371, timezone: "Asia/Jerusalem" },  // Jerusalem

  // Arctic and Antarctica
  { lat: 78.2232, lon: 15.6469, timezone: "Arctic/Longyearbyen" }, // Arctic
  { lat: -90.0000, lon: 0.0000, timezone: "Antarctica/Troll" }     // Antarctica
];



const getTimezoneFromCoordinates = (latitude, longitude) => {
  let closest = null;
  let minDistance = Infinity;

  for (const zone of timezoneMap) {
    const distance = Math.sqrt(
      Math.pow(latitude - zone.lat, 2) + Math.pow(longitude - zone.lon, 2)
    );
    if (distance < minDistance) {
      minDistance = distance;
      closest = zone.timezone;
    }
  }

  return closest || "UTC"; 
};


export const GetTimeZonesOffSet = (latitude, longitude) => {
  const timezone = getTimezoneFromCoordinates(latitude, longitude);
  const offsetMinutes = moment.tz(timezone).utcOffset(); 
  const offsetHours = offsetMinutes / 60;
  return offsetHours;
};
