import './RadarStatistics.css';
import React, {useEffect, useState} from 'react';
import Moment from "react-moment";
import {ButtonGroup} from "@mui/material";
import {Button, Collapse} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faRotate} from "@fortawesome/free-solid-svg-icons";
import StatisticsCard from "../StatisticsCard/StatisticsCard";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import {useTranslation} from "react-i18next";
import GetVehiclesQuery from "../../queries/Statistics/GetVehicles";


export default function RadarStatistics(props) {
    const {t} = useTranslation();

    const showBRadarClasses = props.deviceRadars > 1 ? true : false;

    const [radarPieChartDataset, setRadarPieChartDataset] = useState([[0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0]]);

    const [radarPieChartOptions] = useState({
        chart: {
            width: 380, type: 'pie',
        }, labels: [t('CAR'), t('TRANSPORTER'), t('SHORT TRUCK'), t('LONG TRUCK')], responsive: [{
            breakpoint: 480, options: {
                chart: {
                    width: 240
                }, legend: {
                    show: false
                }, dataLabels: {
                    enabled: false
                }
            }
        }]
    });

    const [isRadarCardOpen, setIsRadarCardOpen] = useState(props.opened);

    const [isRadarDataLoaded, setIsRadarDataLoaded] = useState(false);

    const [radarBoxesSettings, setRadarBoxesSettings] = useState({
        'vehicles_a_b': {'selected': true},
        'vehicles_b_a': {'selected': true},
        'speedings_a_b': {'selected': false},
        'speedings_b_a': {'selected': false}
    });

    const [radarStatistics, setRadarStatistics] = useState({
        'vehicles_a_b': 0,
        'vehicles_b_a': 0,
        'speedings_a_b': 0,
        'speedings_b_a': 0,
        'vehicles_a_b_data': [],
        'vehicles_b_a_data': [],
        'speedings_a_b_data': [],
        'speedings_b_a_data': [],
    });

    const [radarChartOptions] = useState({
        chart: {
            id: 'radar-line-chart', type: 'area', stacked: false, height: 350, zoom: {
                type: 'x', enabled: true, autoScaleYaxis: false
            }, toolbar: {
                autoSelected: 'zoom'
            }
        }, stroke: {
            curve: 'straight',
        }, dataLabels: {
            enabled: false
        }, markers: {
            size: 0,
        },

        fill: {
            type: 'gradient', gradient: {
                shadeIntensity: 1, inverseColors: false, opacityFrom: 0.5, opacityTo: 0, stops: [0, 90, 100]
            },
        }, yaxis: {
            labels: {
                formatter: function (val) {
                    return (val).toFixed(0);
                },
            }, title: {
                text: 'Count'
            },
        }, xaxis: {
            type: 'datetime',
        }, tooltip: {
            shared: false, y: {
                formatter: function (val) {
                    return (val).toFixed(0)
                }
            }
        }
    });

    const [radarChartSeries, setRadarChartSeries] = useState([]);
    const redrawRadarChart = () => {
        let radarChartSeriesHolder = [];


        Object.keys(radarBoxesSettings).forEach(function (key) {

            if (radarBoxesSettings[key].selected) {
                // SHOW
                radarChartSeriesHolder.push({
                    name: key, data: radarStatistics[key + "_data"]
                })

            } else {
                radarChartSeriesHolder.map((item, ix) => {
                    if (item.name === key) {
                        radarChartSeriesHolder.splice(ix, 1);
                    }
                });
            }
        });

        let chart = ApexCharts.getChartByID("radar-line-chart");


        chart.updateSeries(radarChartSeriesHolder).then(() => {
            setRadarChartSeries(() => radarChartSeriesHolder);
        });
    }

    const radarBoxOnClick = (objectKey) => {
        setRadarBoxesSettings(prev => {
            return ({...prev, [objectKey]: {'selected': !prev[objectKey].selected}});
        })
    }
    const getRadarStatistics = () => {
        let startDateISO = props.startDate.toISOString();
        let endDateISO = props.endDate.toISOString();
        setRadarChartSeries([]);
        setIsRadarDataLoaded(false);

        GetVehiclesQuery(props.deviceId, startDateISO, endDateISO, props.offSet).then((result) => {
            const offSet = (props.offSet || 0) * 3600000; 
            const applyOffset = (data) => {

                if(data === undefined || data === null) {
                    return data;
                } 


                return data.map(item => ({
                    x: item.x + offSet, 
                    y: item.y          
                }));
            };
            setRadarStatistics((prev) => {

                return ({
                    ...prev,
                    'vehicles_a_b': result.data.data.vehicles_a_b,
                    'vehicles_b_a': result.data.data.vehicles_b_a,
                    'speedings_a_b': result.data.data.speedings_a_b,
                    'speedings_b_a': result.data.data.speedings_b_a,
                    'vehicles_a_b_data': applyOffset(result.data.data.vehicles_a_b_data),
                    'vehicles_b_a_data': applyOffset(result.data.data.vehicles_b_a_data),
                    'speedings_a_b_data': applyOffset(result.data.data.speedings_a_b_data),
                    'speedings_b_a_data': applyOffset(result.data.data.speedings_b_a_data),
                });
            });

            setRadarPieChartDataset(() => {
                return (result.data.data.pie_matrix);
            })


            setIsRadarDataLoaded(true);


        });
    }


    useEffect(() => {
        getRadarStatistics();
    }, [props.startDate, props.endDate]);

    useEffect(() => {
        if (isRadarDataLoaded) {
            setRadarChartSeries([]);
            redrawRadarChart();
        }
    }, [isRadarDataLoaded]);

    useEffect(() => {
        setRadarChartSeries(() => {
            if (isRadarDataLoaded) {
                redrawRadarChart();
            }
            return ([]);
        });

    }, [radarBoxesSettings]);

    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-primary">
                        <div className="row">
                            <div className="col-6">
                                <h4 className="card-title text-white"
                                    style={{margin: "6px 0px 0px 0px"}}>
                                    {t("Radar data")}, {t("Period: ")}
                                    <Moment format="DD.MM.yyyy">{props.startDate.toISOString()}</Moment>
                                    &nbsp;-&nbsp;
                                    <Moment format="DD.MM.yyyy">{props.endDate.toISOString()}</Moment>
                                </h4>
                            </div>
                            <div className="col text-end" style={{padding: "0px 0px 0px 0px"}}>
                                <ButtonGroup>
                                    <Button
                                        className="btn btn-primary"
                                        onClick={() => setIsRadarCardOpen(!isRadarCardOpen)}
                                        aria-controls="collapsable-pedestrian-card"
                                        aria-expanded={isRadarCardOpen}
                                    >
                                        {isRadarCardOpen && <FontAwesomeIcon icon={faMinus}/>}
                                        {!isRadarCardOpen && <FontAwesomeIcon icon={faPlus}/>}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>

                    </div>
                    <Collapse in={isRadarCardOpen}>
                        <div id="collapsable-pedestrian-card">
                            {isRadarDataLoaded && <div className="card-body">

                                <div className="row text-center">
                                    <div className="col-3"
                                         onClick={() => radarBoxOnClick('vehicles_a_b')}>
                                        <StatisticsCard title={t("Vehicles A->B")}
                                                        number={radarStatistics.vehicles_a_b}
                                                        settings={radarBoxesSettings.vehicles_a_b}/>

                                    </div>
                                    <div className="col-3"
                                         onClick={() => radarBoxOnClick('vehicles_b_a')}>
                                        <StatisticsCard title={t("Vehicles B->A")}
                                                        number={radarStatistics.vehicles_b_a}
                                                        id="crossings_b_a"
                                                        settings={radarBoxesSettings.vehicles_b_a}/>

                                    </div>
                                    <div className="col-3"
                                         onClick={() => radarBoxOnClick('speedings_a_b')}>
                                        <StatisticsCard title={t("Speedings A->B")}
                                                        number={radarStatistics.speedings_a_b}
                                                        id="jaywalking_a_b"
                                                        settings={radarBoxesSettings.speedings_a_b}/>

                                    </div>
                                    <div className="col-3"
                                         onClick={() => radarBoxOnClick('speedings_b_a')}>
                                        <StatisticsCard title={t("Speedings B->A")}
                                                        number={radarStatistics.speedings_b_a}
                                                        id="jaywalking_b_a"
                                                        settings={radarBoxesSettings.speedings_b_a}/>

                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <ReactApexChart options={radarChartOptions}
                                                        series={radarChartSeries} type="area"
                                                        height={350}/>
                                    </div>
                                </div>



                            </div>}

                            {!isRadarDataLoaded && <>
                                <div className="card-body" style={{height: "200px"}}>

                                    <div className="row text-center">
                                        <div className="col-12">
                                            <FontAwesomeIcon icon={faRotate} size="10x" className="fa-spin"
                                                             style={{color: "#4d79f6"}}/>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    </>)

}
