import React, {useEffect, useState} from 'react';
import './DeployedDevices.css';
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAdd, faChartSimple, faCircle, faCog, faExclamationTriangle, faKey, faMapPin, faRotate, faPencil, faTemperature0,
} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {ButtonGroup} from "@mui/material";
import AddDeviceModal from "./Modals/AddDeviceModal";
import InformationModal from "./Modals/InfromationModal";
import {useDispatch} from "react-redux";
import {disableLoading, enableLoading} from "../../../features/loading/loadingSlice";
import GetDevicesQuery from "../../../queries/Devices/GetDevices";
import DeployedDevicesMap from "./DeployedDevicesMap/DeployedDevicesMap";
import withReactContent from "sweetalert2-react-content";
import swal from 'sweetalert2';
import AddApiKeyQuery from "../../../queries/ApiKey/AddApiKey";
import moment from "moment-timezone";
import {Link, useNavigate} from "react-router-dom";


export default function DeployedDevices(props) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [currentUser] = useState(props.currentUser);
    const [currentGroup] = useState(props.currentGroup);
    const [addModalShow, setAddModalShow] = useState(false);
    const [informationModalShow, setInformationModalShow] = useState(false);
    const [devices, setDevices] = useState();
    const [dataTableData, setDataTableData] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState();
    const apiKeyConfirmSwal = withReactContent(swal);
    const apiKeyErrorMessage = withReactContent(swal);
    const apiKeyConfirmMessage = withReactContent(swal);

    const navigate = useNavigate();

    const columns = [{
        name: t('Name'), selector: row => row.sort_id, sortable: true, cell: row => row.name,
    }, {
        name: t('Device status'), selector: row => row.status, sortable: false
    }, {
        name: t('Modification'), selector: row => row.modification, sortable: true, width: "130px"
    }, {
        name: t('Radar count'), selector: row => row.radar_count, sortable: true, right: true
    }, {
        name: '', selector: row => row.buttons, sortable: false, right: true, width: "320px"
    }];

    useEffect(() => {
        fetchDevices();
    }, []);

    const selectDevice = (data) => {
        setSelectedDevice(data);
    }

    const putApiKeyToClipboard = (device_id) => {
        //fist show confirm
        apiKeyConfirmSwal.fire({
            title: t("Are you sure?"),
            text: t("New one time api key will be generated"),
            icon: "warning",
            showCancelButton: true,
        })
            .then(res => {
                if (res.isConfirmed) {
                    //then show loading
                    dispatch(enableLoading());

                    //generate
                    AddApiKeyQuery(device_id).then((response) => {
                        if (response.status !== 200 && response.status !== 201) {
                            dispatch(disableLoading());

                            let message = response.response.data?.message;

                            apiKeyErrorMessage.fire({
                                title: <strong>{t("Error")}</strong>, html: <i>{t(message)}</i>, icon: 'error'
                            }).then();
                        } else {
                            dispatch(disableLoading());

                            let apiKey = response.data.data.api_key;

                            navigator.clipboard.writeText(apiKey).then();

                            apiKeyConfirmMessage.fire({
                                title: <strong>{t("API key")}</strong>,
                                html: <i>{apiKey}</i>,
                                showCancelButton: true,
                                cancelButtonText: <i>{t("Copy to clipboard")}</i>,
                                cancelButtonColor: "#4c78f4",
                                icon: 'warning'
                            }).then((res) => {
                                if (!res.isConfirmed) {
                                    navigator.clipboard.writeText(apiKey);

                                    apiKeyConfirmMessage.fire({
                                        title: <strong>{t("Success")}</strong>,
                                        html: <i>{t("API key copied to clipboard")}</i>,
                                        icon: 'success'
                                    }).then();
                                }
                            })
                        }
                    });
                }
            });
    }

    const isDeviceOnlineMummu = (core_id, heartbeat) => {
        let online = false;

        let latestUptime = null;
        // miinus viis mintsa
        let now = moment(Date.now()).subtract(5, 'minutes');

        for (const [, value] of Object.entries(heartbeat)) {
            if (core_id === value.core_id) {
                latestUptime = value.updated_at;
            }
        }

        if (latestUptime) {
            if (moment(now).isBefore(latestUptime)) {
                online = true;
            }
        }

        return {
            "online": online,
            "latest_uptime": latestUptime ? moment(latestUptime).format('YYYY-MM-DD HH:mm') : t("Never"),
            "has_sent_heartbeat": !!latestUptime
        };
    }

    const checkLastBlink = (lastBlinkDate) => {
        if (!lastBlinkDate) return false; // Handle empty or invalid date
        const lastBlinkMoment = moment(lastBlinkDate, "YYYY-MM-DDTHH.mm.ss");
        const currentMoment = moment();

        // Check if the last blink is more than 1 day ago
        const diffInDays = currentMoment.diff(lastBlinkMoment, 'hours');

        console.log(diffInDays);
        
        if (diffInDays > 12) {
            // Perform the action when more than 1 day has passed
            return false; // This indicates that more than 1 day has passed
        }

        return true; // Last blink is within 1 day
    }


    const drawOnlineBalls = (device) => {

	return <>
		{device.device_heartbeats.map(i => (
<>			
<FontAwesomeIcon
			    style={{marginRight: '5px'}}
                            title={t("Core: ")+i.core_id+" "+isDeviceOnlineMummu(i.core_id, device.device_heartbeats).latest_uptime}
                            icon={faCircle}
                            color={isDeviceOnlineMummu(i.core_id, device.device_heartbeats).online ? "green" : "red"}/></>
		))}
		{" " + " "}</>
    }

    const fetchDevices = () => {
        dispatch(enableLoading());
        GetDevicesQuery(currentGroup.id).then((response) => {
            if (response.status === 200) {
                let devices = response.data.data;

                setDevices(devices);
               
                let dataTableArray = [];
                
                for (const [, value] of Object.entries(devices)) {
                    let hasBlinked = checkLastBlink(value.last_blink_a) && checkLastBlink(value.last_blink_b) && (checkLastBlink(value.last_blink_b) !== null);
                    console.log(checkLastBlink(value.last_blink_b) + value.name)


                    let row = {
                        id: value.id,
                        status: drawOnlineBalls(value),
                        sort_id:parseInt(value.name.split(" ")[1]),
                        name: <>{value.name} {value.device_notifications_count > 0 && <> <Link
                            to="/notifications"><FontAwesomeIcon title={t("Device has notifications")}
                                                                 icon={faExclamationTriangle}
                                                                 color="red"></FontAwesomeIcon></Link></>}</>,
                        radar_count: value.radars,
                        modification: value.modification,
                        buttons: <ButtonGroup>
                            {(currentUser && currentGroup.id === 1) &&
                                <button type="button" className="btn btn-warning btn-icon-square-sm"
                                        title={t("Get API key")}
                                        style={{marginRight: "2px"}}
                                        onClick={() => putApiKeyToClipboard(value.id)}>
                                    <FontAwesomeIcon icon={faKey}/>
                                </button>}
                            <button type="button" className="btn btn-primary btn-icon-square-sm"
                                    title={t("Show device on map")}
                                    style={{marginRight: "2px"}} onClick={() => selectDevice(value)}>
                                <FontAwesomeIcon icon={faMapPin}/>
                            </button>
                            {(currentUser && currentGroup.id === 1) &&
                            <>
                            <button type="button" className="btn btn-primary btn-icon-square-sm"
                                    title={t("Edit device")}
                                    style={{marginRight: "2px"}} onClick={() => {
                                selectDevice(value);
                                setAddModalShow(true)
                            }}>

                                <FontAwesomeIcon icon={faPencil}/>
                            </button>
                            
                            <button type="button"
                                    
                            className={hasBlinked ? "btn btn-icon-square-sm btn-success" : "btn btn-icon-square-sm btn-danger"} 
                            title={t("Device information")}
                            onClick={() => {
                                selectDevice(value);
                                setInformationModalShow(!informationModalShow);
                            }}
                            style={{marginRight: "2px"}}>
                        <FontAwesomeIcon icon={faExclamationTriangle}/>
                             </button>
                             </>
                            
                            }
                            <button type="button"
                                    
                                    className="btn btn-primary btn-icon-square-sm"
                                    title={t("Device sensors")}
                                    onClick={() => navigate("/sensors/" + value.id)}
                                    style={{marginRight: "2px"}}>
                                <FontAwesomeIcon icon={faTemperature0}/>
                            </button>
                            {(currentUser && currentGroup.id === 1) &&
                            <button type="button"
                                    
                                    className="btn btn-primary btn-icon-square-sm"
                                    title={t("Device settings")}
                                    onClick={() => navigate("/device-settings/" + value.id)}
                                    style={{marginRight: "2px"}}>
                                <FontAwesomeIcon icon={faCog}/>
                            </button>
                            }
                            <button type="button"
                                    
                                    className="btn btn-primary btn-icon-square-sm"
                                    title={t("Device info and statistics")}
                                    onClick={() => navigate("/statistics/" + value.id)}
                                    style={{marginRight: "2px"}}>
                                <FontAwesomeIcon icon={faChartSimple}/>
                            </button>
                           

                            

                        </ButtonGroup>
                    }

                    dataTableArray.push(row);
                }

                setDataTableData(dataTableArray);

                dispatch(disableLoading());
            }
        });
    }

    return (<>

        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-primary">
                        <div className="row">
                            <div className="col-6">
                                <h4 className="card-title text-white"
                                    style={{margin: "6px 0px 0px 0px"}}>{t("Deployed devices")}</h4>
                            </div>
                            <div className="col text-end" style={{padding: "0px 0px 0px 0px"}}>
                                <ButtonGroup>
                                    <button type="button" className="btn btn-primary btn-icon-square-sm"
                                            onClick={() => fetchDevices()}>
                                        <FontAwesomeIcon icon={faRotate}/>
                                    </button>
                                    {(currentUser && currentGroup.id === 1) &&
                                        <Button className="btn btn-primary" style={{padding: "0px 0px 0px 0px"}}
                                                onClick={() => {
                                                    setSelectedDevice(null);
                                                    setAddModalShow(true)
                                                }}>
                                            <FontAwesomeIcon icon={faAdd}/> {t("Add device")}
                                        </Button>}
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <DataTable columns={columns} data={dataTableData} pagination paginationPerPage={30}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <DeployedDevicesMap
            devices={devices}
            selectedDevice={selectedDevice}
        />

        <AddDeviceModal
            show={addModalShow}
            selectedDevice={selectedDevice}
            hideFn={() => {
                setAddModalShow(false);
                fetchDevices()
            }}
            loadFn={() => fetchDevices()}
        />
        <InformationModal
            show={informationModalShow}
            selectedDevice={selectedDevice}
            hideFn={() => {
                setInformationModalShow(false);
            }}
        />
    </>);
}
