import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useTranslation} from "react-i18next";
import Form from 'react-bootstrap/Form';
import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {disableLoading, enableLoading} from "../../../../features/loading/loadingSlice";
import withReactContent from "sweetalert2-react-content";
import swal from 'sweetalert2';
import AddDeviceQuery from "../../../../queries/Devices/AddDevice";
import EditDeviceQuery from "../../../../queries/Devices/EditDevice";

export default function AddDeviceModal(props) {
    const {t} = useTranslation();
    const errorMessage = withReactContent(swal);
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [modification, setModification] = useState("M");
    const [radars, setRadars] = useState(0);
    const [airly, setAirly] = useState("");
    const [tarktee, setTarktee] = useState("");
    const [speedTrigger, setSpeedTrigger] = useState("");

    useEffect(() => {
        if (props.selectedDevice) {
            setName(props.selectedDevice.name);
            setAirly(props.selectedDevice.airly_id);
            setModification(props.selectedDevice.modification);
            setSpeedTrigger(props.selectedDevice.speed_trigger);
            setRadars(props.selectedDevice.radars);
 	    setTarktee(props.selectedDevice.tarktee);	
            let coordinates = props.selectedDevice.coordinates;
            const coordArray = coordinates.split(";");
            setLatitude(coordArray[0]);
            setLongitude(coordArray[1]);
        }
    }, [props.selectedDevice])

    const handleRadarChange = (e) => {
        setRadars(e.target.value);
    }
    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleLongitudeChange = (e) => {
        setLongitude(e.target.value);
    }

    const handleLatitudeChange = (e) => {
        setLatitude(e.target.value);
    }

    const handleModificationChange = (e) => {
        setModification(e.target.value);
    }

    const handleAirlyChange = (e) => {
        setAirly(e.target.value);
    }

    const handleTarkteeChange = (e) => {
        setTarktee(e.target.value);
    }

    const handleSpeedTriggerChange = (e) => {
        setSpeedTrigger(e.target.value);
    }

    const cleanAndCloseModal = () => {
        setName(null);
        setLatitude(null);
        setLongitude(null);
        setRadars(0);
        setModification("M");
        setAirly(null);
        setSpeedTrigger(null);
	setTarktee("");
        props.loadFn();
        props.hideFn();
    }


    const saveDevice = () => {
        dispatch(enableLoading());

        if (!props.selectedDevice) {
            AddDeviceQuery(name, airly, speedTrigger, modification,radars, latitude + ";" + longitude, tarktee).then((response) => {
                if (response.status !== 200 && response.status !== 201) {
                    dispatch(disableLoading());

                    let message = response.response.data?.message;

                    if (message === "Validation error") {
                        let data = response.response.data.data;

                        message = "";

                        for (const [, value] of Object.entries(data)) {
                            message = message + value[0] + "<br/>";
                        }
                    }


                    errorMessage.fire({
                        title: <strong>Error!</strong>, html: message, icon: 'error'
                    }).then();
                }
            });
        } else {
            EditDeviceQuery(props.selectedDevice.id, name, airly, speedTrigger, modification, radars, latitude + ";" + longitude, tarktee).then((response) => {
                if (response.status !== 200 && response.status !== 201) {
                    dispatch(disableLoading());

                    let message = response.response.data?.message;

                    if (message === "Validation error") {
                        let data = response.response.data.data;

                        message = "";

                        for (const [, value] of Object.entries(data)) {
                            message = message + value[0] + "<br/>";
                        }
                    }


                    errorMessage.fire({
                        title: <strong>Error!</strong>, html: message, icon: 'error'
                    }).then();
                }
            });
        }
        dispatch(disableLoading());
        cleanAndCloseModal();
    }

    return (<Modal {...props} onHide={cleanAndCloseModal}>
        <Modal.Header>
            {!props.selectedDevice && <Modal.Title>{t("Add device")}</Modal.Title>}
            {props.selectedDevice && <Modal.Title>{props.selectedDevice.name}</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="nameCtl">
                    <Form.Label>{t("Device name")}</Form.Label>
                    <Form.Control type="name" placeholder={t("Enter device name")} value={name}
                                  onChange={handleNameChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="coordinatesCtl">
                    <Form.Label>{t("Coordinates")}</Form.Label>
                    <Form.Control type="text" placeholder={t("Latitude")} value={latitude}
                                  onChange={handleLatitudeChange}/>
                    <Form.Control type="text" placeholder={t("Longitude")} value={longitude}
                                  onChange={handleLongitudeChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="coordinatesCtl">
                    <Form.Label>{t("Modification")}</Form.Label><br/>
                    <Form.Select value={modification} onChange={handleModificationChange}>
                        <option value="M">{t("M")}</option>
                        <option value="S">{t("S")}</option>
                        <option value="HYBRID">{t("Hybrid")}</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="coordinatesCtl">
                    <Form.Label>{t("Radar count")}</Form.Label><br/>
                    <Form.Select value={radars} onChange={handleRadarChange}>
                        <option value="0">{t("No radar")}</option>
                        <option value="1">{t("1")}</option>
                        <option value="2">{t("2")}</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="airlyCtl">
                    <Form.Label>{t("Airly id")}</Form.Label>
                    <Form.Control type="name" placeholder={t("Enter airly id")} value={airly}
                                  onChange={handleAirlyChange}/>
                </Form.Group>
		             <Form.Group className="mb-3" controlId="tarkteeCtl">
                    <Form.Label>{t("Tarktee asukoht")}</Form.Label>
                    <Form.Control type="name" placeholder={t("Tarktee asukoht")} value={tarktee}
                                  onChange={handleTarkteeChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="speedTriggerCtl">
                    <Form.Label>{t("Speed trigger")}</Form.Label>
                    <Form.Control type="name" placeholder={t("Enter speed trigger")} value={speedTrigger}
                                  onChange={handleSpeedTriggerChange}/>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={cleanAndCloseModal}>
                {t("Close")}
            </Button>
            <Button variant="primary" onClick={() => {
                saveDevice()
            }}>
                {t("Save")}
            </Button>
        </Modal.Footer>
    </Modal>);
}
