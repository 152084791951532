const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
export const CONFIG = {
    IS_A1: false, VERSION: "2.0 Build 1.0",


    API_URL: isLocalhost ? "http://localhost/api/v1/" : "https://apiv2.bercman.com/api/v1/",
};


