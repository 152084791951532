import React, { useState, useEffect } from "react";
import "./LightPost.css";
import {faExclamationTriangle, faPersonWalking} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"; // For styling

const LightPost = ({ isSignGlowing = false, isLightsBlinking = false, isFlipped = false }) => {
    const [currentLight, setCurrentLight] = useState(null); // Start with no light lit

    useEffect(() => {
        let interval;
        if (isLightsBlinking) {
            interval = setInterval(() => {
                setCurrentLight((prev) => (prev === 0 ? 1 : 0));
            }, 500); // Switch every 500ms
        } else {
            setCurrentLight(null); // No lights lit if not blinking
        }

        return () => clearInterval(interval);
    }, [isLightsBlinking]);

    return (
        <div className="traffic-component">
            {/* Square sign */}
            <div
                className={`traffic-sign ${isSignGlowing ? "glowing" : ""}`}
            >
                {!!isFlipped &&
                    <FontAwesomeIcon style={{padding: "15px 25px 25px 30px", height: "5em", transform: "scaleX(-1)"}}
                                     icon={faPersonWalking}
                                     color="black" />
                }

                {!!!isFlipped &&
                    <FontAwesomeIcon style={{padding: "15px 25px 25px 30px", height: "5em"}}
                                     icon={faPersonWalking}
                                     color="black" />
                }


            </div>

            {/* Post */}
            <div className="traffic-post">
                {/* Lights */}
                <div
                    className={`traffic-light ${currentLight === 0 ? "lit" : "off"}`}
                ></div>
                <div
                    className={`traffic-light ${currentLight === 1 ? "lit" : "off"}`}
                ></div>
            </div>
        </div>
    );
};

export default LightPost;
